<template>
  <div class="parkingRecord">
    <back-header @back="back" title="泊车记录"></back-header>
    <div class="content">
      <van-pull-refresh
        class="pull-refresh"
        v-model="isRefreshing"
        success-text="刷新成功"
        @refresh="onRefresh"
        :disabled="disabled"
      >
        <div class="content-inner">
          <div v-if="list" class="mission-count">
            共<span>{{ list.length }}</span
            >条
          </div>
          <ul class="mission-list" v-if="list && list.length">
            <li
              v-for="item in list"
              :key="item.subMissionId"
              class="mission-item"
            >
              <div class="mission-type">
                <div v-if="item.taskType === 2">泊出任务</div>
                <div v-else>泊入任务</div>
                <div class="type-text" :class="'status-' + item.taskStatus">
                  {{ taskStatusMap[item.taskStatus] || '异常结束' }}
                </div>
              </div>
              <div>
                <div>POI</div>
                <div>{{ item.poiName }}</div>
              </div>
              <div v-if="item.startParkingName">
                <div>泊车起点</div>
                <div>{{ item.startParkingName }}</div>
              </div>
              <div v-if="item.finishParkingName">
                <div>泊车终点</div>
                <div>{{ item.finishParkingName }}</div>
              </div>
              <div>
                <div>任务创建时间</div>
                <div style="font-weight: 600">{{ item.createTime }}</div>
              </div>
              <div v-if="item.emitTime">
                <div>任务下发时间</div>
                <div style="font-weight: 600">{{ item.emitTime }}</div>
              </div>
              <div v-if="item.finishTime">
                <div>任务结束时间</div>
                <div style="font-weight: 600">{{ item.finishTime }}</div>
              </div>
              <div>
                <div>任务id</div>
                <div>{{ item.taskId }}</div>
              </div>
            </li>
          </ul>
          <div v-else-if="list !== null" class="none-data">
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
              alt=""
            />
            <span>暂无数据</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import BackHeader from '@/components/BackHeader.vue';
import { queryRelatedParkingTasks } from '@/api/apiv2';

export default {
  components: {
    BackHeader,
  },
  data() {
    return {
      subMissionId: null,
      isRefreshing: false,
      disabled: false,
      list: null,
      timer: null,
      taskStatusMap: {
        0: '未下发',
        1: '执行中',
        2: '已完成',
        3: '异常结束',
        4: '下发失败',
      },
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    onRefresh() {
      this.isRefreshing = true;
      this.getParkingRecord();
    },
    getParkingRecord(loading = false) {
      if (loading) this.$loadingCircle.start();

      queryRelatedParkingTasks({
        subMissionId: this.subMissionId,
      })
        .then((res) => {
          this.list = res;
        })
        .finally(() => {
          if (loading) this.$loadingCircle.end();
          this.isRefreshing = false;
        });
    },
  },
  created() {
    const { subMissionId, missionStatus } = this.$route.query;
    this.subMissionId = subMissionId;
    this.disabled = missionStatus != '1';
    this.getParkingRecord(true);
    clearInterval(this.timer);
    if (!this.disabled) {
      this.timer = setInterval(() => {
        this.getParkingRecord();
      }, 3000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.parkingRecord {
  font-size: initial;
  background: #f6f7f8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow: auto;
    max-height: calc(100vh - 40px);
    .pull-refresh {
      min-height: 100%;
    }
    .content-inner {
      .mission-count {
        height: 48px;
        line-height: 48px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(151, 152, 171, 1);

        span {
          color: rgba(252, 126, 54, 1);
          margin: 0 4px;
        }
      }
      .mission-list {
      }
      .mission-item {
        margin: 0 15px 15px 15px;
        padding: 15px;
        box-sizing: border-box;
        background: white;
        border-radius: 12px;
        .mission-type {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          > div {
            &:nth-child(1) {
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0px;
              color: rgba(0, 0, 0, 1);
            }
          }

          .type-text {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0px;
            color: rgba(255, 45, 85, 1);
            &.status-0 {
              color: rgba(244, 177, 0, 1);
            }
            &.status-1 {
              color: rgba(66, 110, 255, 1);
            }
            &.status-2 {
              color: rgba(38, 197, 117, 1);
            }
            &.status-3 {
              color: rgba(255, 45, 85, 1);
            }
            &.status-4 {
              color: rgba(255, 45, 85, 1);
            }
          }
        }
        > div:not(.mission-type) {
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > div {
            &:nth-child(1) {
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              color: rgba(151, 152, 171, 1);
            }
            &:nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              color: rgba(0, 0, 0, 1);
            }
          }
        }
      }
      .none-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        img {
          width: 130px;
          height: 130px;
          margin-top: 30%;
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
