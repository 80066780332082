<template>
  <div class="back-header">
    <div class="navBar">
      <div class="back-btn" @click="backClick">
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
      </div>
      <div class="title">{{ title }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {
    backClick() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.back-header {
  height: 40px;
  .navBar {
    position: fixed;
    height: 40px;
    width: 100vw;
    display: flex;
    align-items: center;
    height: 40px;
    background: white;
    padding: 0 8px 0 10px;
    box-sizing: border-box;
    .back-btn {
      position: absolute;
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }

    .title {
      width: 100%;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 1);
      text-align: center;
    }
  }
}
</style>
